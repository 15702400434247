<template>
  <div class="text-h6 text-center q-pa-md shadow rounded">
    {{ $t('Inventory') + ': ' + data.id }}
  </div>
</template>

<script>
export default {
  name: 'InventoryObject',
  props: {
    data: {
      type: Object,
      default () {
        return null
      }
    }
  }
}
</script>
